import React from 'react';

//we can it to be globa so use the contect API we need to split the stae and the dispatch into two
//Example of how this works on YouTube
//https://www.youtube.com/watch?v=ThCfN5WJ0cU&list=PLb0ENDTKBLSWRWLqznSfHarX2tQEs0NSw

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

//use a reducer instead of state
const initialState = {
    //this can be anythign we want to setup
    theme: 'light',
    mobile: 'closed'
}

function reducer(state, action){
    switch (action.type) {
        case 'TOGGLE_THEME':{
            return {
                ...state,
                theme: state.theme === 'light' ? 'dark' : 'light'
            }
        }
        //break;
        case 'MOBILE_NAV':{
            return {
                ...state,
                mobile: state.mobile === 'closed' ? 'open' : 'closed'
            }
        }
        //break;
        case 'MOBILE_NAV_OFF':{
            return {
                ...state,
               // mobile: state.mobile === 'open' ? 'closed' : 'closed'
                mobile: state.mobile = 'closed'
            }
        }
        //break;
        default:
        throw new Error('Bad action type')
    }
}

const GlobalContextProvider = ({children}) => {
    // dispatch is the action type so we can call TOGGLE_THEME
    const[state, dispatch] = React.useReducer(reducer, initialState)
    return <GlobalStateContext.Provider value={state}><GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider></GlobalStateContext.Provider>
}

export default GlobalContextProvider;