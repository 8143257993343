/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import './src/styles/global.css';

  import React from 'react'
  import GlobalContextProvider from './src/context/GlobalContextProvider';

  //MDX rendering
  import { MDXProvider } from "@mdx-js/react"

  //codeblock styling
  import CodeBlock from './src/components/CodeBlock'; // The wrapper you created in step 2

  //create any frontend overrides to format the MDX strings fecthed from the graphCMS post
const MyH1 = props => <h1 style={{ color: "tomato" }} {...props} />
// const MyParagraph = props => (
//   <p style={{ fontSize: "18px", lineHeight: 1.6, color: "purple" }} {...props} />
// )
const MyTldr = props =>(
<div className="tldr"><h2>tl;dr;</h2><p>{props.value}</p></div>
)
  
  


//add them to the components and later pass these to the MDX renderer:)
const components = {
  h1: MyH1,
 // p: MyParagraph,
  pre: CodeBlock,
  Tldr: MyTldr,
}







//THE GLOBAL CONTEXT PART persisting state for light/dark mode
export const wrapRootElement = ({ element }) => {
  return (

      <GlobalContextProvider>
      <MDXProvider components={components}>{element}</MDXProvider>
      </GlobalContextProvider>
    
  )
}



//THE MDX PART

// export const wrapRootElement = ({ element }) => (
//   <MDXProvider components={components}>{element}</MDXProvider>
// )





///OLD CRAP BELOW


// import {useContext} from "react"
// import { GlobalDispatchContext, GlobalStateContext } from "./src/context/GlobalContextProvider";


// export const onClientEntry = () => {
//   window.onload = () => { alert('page loaded') }
// }
// Logs when the client route changes
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   // const gdispatch = useContext(GlobalDispatchContext);
//   console.log("new pathname", location.pathname)
  
// }
