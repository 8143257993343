exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-demo-js": () => import("./../../../src/pages/ar-demo.js" /* webpackChunkName: "component---src-pages-ar-demo-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-colours-js": () => import("./../../../src/pages/colours.js" /* webpackChunkName: "component---src-pages-colours-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-old-payground-js": () => import("./../../../src/pages/old-payground.js" /* webpackChunkName: "component---src-pages-old-payground-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-otta-01-js": () => import("./../../../src/pages/work/otta-01.js" /* webpackChunkName: "component---src-pages-work-otta-01-js" */),
  "component---src-pages-work-otta-02-js": () => import("./../../../src/pages/work/otta-02.js" /* webpackChunkName: "component---src-pages-work-otta-02-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/PostPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */)
}

